<template>
  <div>
    <hi-tab-layout :info="info"></hi-tab-layout>
    <div style="background-color: white;padding: 25px 40px;min-height: 781px">
      <router-view></router-view>
    </div>
  </div>

</template>

<script>
import HiTabLayout from "@/components/HiTabLayout"

export default {
  name: "Personal",

  components: {
    HiTabLayout
  },

  data(){
    return {
      info: [
        {
          label: '个人信息',
          name: 'Info',
          url: 'info'
        },
        {
          label: '账号安全',
          name: 'AccountSecurity',
          url: 'account-security'
        },
        {
          label: '支付设置',
          name: 'PaymentSettings',
          url: 'payment-settings'
        },
        {
          label: '机构认证',
          name: 'CompanyCertification',
          url: 'company-certification'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
